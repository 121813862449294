<template>
    <div>
        <svg width="160px" height="160px" viewbox="0 0 100 100" style="transform: rotate(-22.5deg); text-align: center">
            <!-- <circle cx="100" cy="100" r="40" fill="tomato"/> -->
            <!-- #00008B -->
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="50" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="0"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="0"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.NE?'#F1F0F0':'#6DA9DB'" stroke-dasharray="251.2" stroke-dashoffset="2"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="31.4"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.N?'#F1F0F0':'#A0C0E5'" stroke-dasharray="251.2" stroke-dashoffset="33.4"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="62.8"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.NW?'#F1F0F0':'#6DA9DB'" stroke-dasharray="251.2" stroke-dashoffset="64.8"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="94.2"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.W?'#F1F0F0':'#A0C0E5'" stroke-dasharray="251.2" stroke-dashoffset="96.2"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="125.6"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.SW?'#F1F0F0':'#6DA9DB'" stroke-dasharray="251.2" stroke-dashoffset="127.6"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="157"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.S?'#F1F0F0':'#A0C0E5'" stroke-dasharray="251.2" stroke-dashoffset="159"/>
            
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="188.4"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.SE?'#F1F0F0':'#6DA9DB'" stroke-dasharray="251.2" stroke-dashoffset="190.4"/>

            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" stroke="#564A6E" stroke-dasharray="251.2" stroke-dashoffset="219.8"/>
            <circle cx="80" cy="80" r="40" fill="transparent" stroke-width="40" :stroke="compass.E?'#F1F0F0':'#A0C0E5'" stroke-dasharray="251.2" stroke-dashoffset="221.8"/>
            
            <text x="100" y="5" :fill="compass.N?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">N</text>
            <text x="65" y="17" :fill="compass.NW?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">NW</text>
            <text x="130" y="15" :fill="compass.NE?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">NE</text>
            <text x="55" y="48" :fill="compass.W?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">W</text>
            <text x="145" y="46" :fill="compass.E?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">E</text>
            <text x="67" y="80" :fill="compass.SW?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">SW</text>
            <text x="132" y="78" :fill="compass.SE?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">SE</text>
            <text x="103" y="90" :fill="compass.S?'#F1F0F0':'#564A6E'" font-size="10" font-weight="bold" style="transform: rotate(22.5deg)">S</text>
            <!-- stroke-dashoffset = perimeter - perimeter * amount / 100 (251.2 - 251.2*80/100)-->
            <!-- stroke-dasharray perimeter = 2 * 3.14 * radius (2 * 3.14 * 40 = 251.2)-->
        </svg>
    </div>
</template>

<script>
    export default {
        props: {
            direction: { type: Array, default: () => [] },
        },
        data() {
            return {
                _direction: [],
                compass: {
                    N: false, NW: false, NE: false, W: false, E: false, SW: false, SE: false, S: false
                }
            }
        },
        created() {
            this.changeCompass()
        },
        mounted() {
            this.changeCompass()
        },
        watch: {
            direction: {
                handler: function (val, oldVal) {
                    if(val!=oldVal) {
                        this.changeCompass()
                    }
                }
            },
        },
        methods: {
            changeCompass() {
                var d = this.direction
                this._direction = []

                this._direction = d?d:[]
                this.compass.N = this._direction.includes('N')
                this.compass.NW = this._direction.includes('NW')
                this.compass.NE = this._direction.includes('NE')
                this.compass.W = this._direction.includes('W')
                this.compass.E = this._direction.includes('E')
                this.compass.SW = this._direction.includes('SW')
                this.compass.SE = this._direction.includes('SE')
                this.compass.S = this._direction.includes('S')
            }
        },
    }
</script>
<style scoped>
/* custom1: #6DA9DB #A0C0E5 #564A6E */
</style>