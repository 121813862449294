<template>
  <div>
    <CRow>
      <FlashMessage :position="'right top'" style="z-index: 2000;"></FlashMessage>
      <div class="btn-sidebar-awos" @click="showfilter" :style="'right: 0vw; text-align: center;'">
        <v-icon :name="isFilter?'caret-up':'caret-down'" style="height: 100%; vertical-align: middle;"/>
      </div>
      <CCol xl="12" style="text-align: center;" v-if="isHLoading">
        <CSpinner
          color="info"
          style="width:1rem;height:1rem;"
        />
      </CCol>
      <CCol xl="4" v-if="!isHLoading && isFilter">
        <CRow>
          <CCol tag="label" xl="3" class="col-form-label font-weight-bold padding-0 font-08">
            AWOS Lanud:
          </CCol>
          <CCol xl="9" class="padding-0" style="padding-right: 10px;">
            <v-select 
                :reduce="airports => airports.value"
                v-model="airport_id"
                :options="airports"
                placeholder="Please select air base"
                style="background-color: #FFF;"
                @input="onChangeAirbase"
            />
          </CCol>
          <CCol xl="10" class="padding-0"><small class="form-text text-muted w-100">{{errors.airport_id}}</small></CCol>
        </CRow>
      </CCol>
      <CCol xl="5" v-if="!isHLoading && isFilter">
        <CRow v-if="!isRefresh">
          <CCol tag="label" xl="1" class="col-form-label font-weight-bold padding-0 font-08">
            Show:
          </CCol>
          <CCol xl="11" class="">
            <multiselect
              @input="changeShow"
              :value="value_show"
              :options="showdash"
              track-by="value"
              label="label"
              :multiple="true"
              placeholder="Choose Showing"
              :close-on-select="false"
              :clear-on-select="false">
            </multiselect>
          </CCol>
        </CRow>
      </CCol>
      <CCol xl="3" v-if="!isHLoading && isFilter">
        <CRow>
          <CCol tag="label" xl="12" class="col-form-label font-weight-bold padding-0">
            Now: {{timestamp}}
          </CCol>
          <CCol tag="label" xl="12" class="col-form-label font-weight-bold padding-0">
            Station: {{awosdata?awosdata.retrieved_time:'-'}}
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <CRow :class="{'mt-3': isFilter, 'margin-0': true}">
      <CCard class="col-xl-12 padding-0">
        <CCardBody class="padding-0">
          <CCol xl="12" style="text-align: center;" v-if="isRefresh">
            <CSpinner
              color="info"
              style="width:3rem;height:3rem;"
            />
          </CCol>
          <CRow v-if="runways.length>0" class="mt-1 margin-0 d-flex justify-content-center">
            
            <!-- Col 1 -->
            <CCol v-for="(ks, ids) in filter_show" :key="ids" xl="3" class="padding-0">
              <CCol v-if="getViewRunway(ks)" xl="12" class="text-center padding-0 font-dawos" style="line-height: 1;">
                <CRow class="col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="padding-0 mt-2">
                    <span class="header-title card-title font-weight-bold text-center mt-1 col-xl-12">Magnetic Winds RWY {{valrw(runways[getIndexShow(ks)])}}</span>
                  </CCol>
                  <CCol xl="12" class="padding-0">
                    <small class="col-xl-12 text-dark">Active</small>
                  </CCol>
                  <CCol xl="12" class="padding-0 mt-2">
                    <MetarAwosRadar :arrow="arrow_rotate[getIndexShow(ks)]" :knot="knot_val[getIndexShow(ks)]" :flight="flight_rotate[getIndexShow(ks)]" style="width:200px; height:200px; line-height: 1.5; text-align: left; margin: 0 auto;"/>
                  </CCol>
                </CRow>
                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>Gust</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Variable Winds</small></CCol>
                  <CCol xl="2" class="padding-0"><small>Cross</small></CCol>
                  <CCol xl="2" class="padding-0"><small>Head/Tail</small></CCol>
                  <CCol xl="2" class="padding-0"></CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-07">{{valstr(awosdata.runways[getIndexShow(ks)].wind_gust_speed)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-07">{{valstr(awosdata.runways[getIndexShow(ks)].variable_wind)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-07">{{valstr(awosdata.runways[getIndexShow(ks)].wind_cross)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-07">{{valstr(awosdata.runways[getIndexShow(ks)].wind_head_tail)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0"></CCol>
                </CRow>
                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="2" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>Speed</small></CCol>
                  <CCol xl="2" class="padding-0"><small>Direction</small></CCol>
                  <CCol xl="1" class="padding-0"><small>Min</small></CCol>
                  <CCol xl="1" class="padding-0"><small>Max</small></CCol>
                  <CCol xl="2" class="padding-0"><small>Cross</small></CCol>
                  <CCol xl="2" class="padding-0"><small>Head/Tail</small> </CCol>

                  <CCol xl="2" class="padding-0"><small class="font-weight-bold">2 Min.</small></CCol>
                  <CCol xl="2" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].wind_speed_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].wind_speed)}}
                    </span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].wind_direction_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].wind_direction)}}
                    </span>
                  </CCol>
                  <CCol xl="1" class="padding-0">
                    <span class="font-weight-bold font-07 font-blues">-</span>
                  </CCol>
                  <CCol xl="1" class="padding-0">
                    <span class="font-weight-bold font-07 font-oranges">-</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-07">-</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-07">-</span>
                  </CCol>
                </CRow>
                <CRow class="mt-4 col-xl-12" style="line-height: 1.2;">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">
                      Visibility {{valrw(runways[getIndexShow(ks)])}} - <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].visibility_source)}" style="display: none;">
                        {{valnum(awosdata.runways[getIndexShow(ks)].visibility)}}
                      </span> {{valstr(awosdata.runways[getIndexShow(ks)].visibility_unit)}}
                    </small>
                  </CCol>
                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>Minute</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Average</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Min</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Max</small></CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>1 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[1].max)}}</span>
                  </CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>2 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[2].max)}}</span>
                  </CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>10 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].visibility_modified[10].max)}}</span>
                  </CCol>
                </CRow>
                <CRow class="mt-4 col-xl-12" style="line-height: 1.2;">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">
                      RVR {{valrw(runways[getIndexShow(ks)])}} - <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].rvr_source)}" style="display: none;">
                        {{valnum(awosdata.runways[getIndexShow(ks)].rvr)}}
                      </span> {{valstr(awosdata.runways[getIndexShow(ks)].rvr_unit)}}
                    </small>
                  </CCol>
                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>Minute</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Average</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Min</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Max</small></CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>1 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[1].max)}}</span>
                  </CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>2 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[2].max)}}</span>
                  </CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>10 Min.</small></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-greys">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].average)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-blues">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].min)}}</span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09 font-oranges">{{valnum(awosdata.runways[getIndexShow(ks)].rvr_modified[10].max)}}</span>
                  </CCol>

                  <CCol xl="12" class="padding-0 mt-2">
                    <span><small>Trend: </small><b>-</b><small class="ml-2">RLS: </small> <b>-</b></span>
                  </CCol>
                </CRow>
              </CCol>
            
              <!-- Col 2 -->
              <CCol v-if="getViewLightning(ks)" xl="12" class="text-center padding-0 font-dawos" style="line-height: 1.2;">
                <CRow class="col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="padding-0 mt-2">
                    <span class="header-title card-title font-weight-bold text-center mt-1 col-xl-12">Lightning RWY {{valrw(runways[getIndexShow(ks)])}}</span>
                  </CCol>
                  <CCol xl="12" class="padding-0 mt-2">
                    <!-- <CompassSVG :direction="valarr(awosdata.runways[getIndexShow(ks)].lightning_direction)"/> -->
                    <WindDirection :direction="valarr(awosdata.runways[getIndexShow(ks)].lightning_direction)" :center="valnum(awosdata.runways[getIndexShow(ks)].lightning_center)" :secondary="valnum(awosdata.runways[getIndexShow(ks)].lightning_secondary)" style="width:200px; height:200px; line-height: 1.5; text-align: left; margin: 0 auto;"/>
                  </CCol>
                  <CCol xl="12" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].lightning_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].lightning_source)}">
                      {{valstr(awosdata.runways[getIndexShow(ks)].lightning)}}
                    </span>
                  </CCol>
                </CRow>
                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">Present Weather {{valrw(runways[getIndexShow(ks)])}}</small>
                  </CCol>
                  <CCol xl="12" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].present_weather_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].present_weather_source)}">
                      {{valstr(awosdata.runways[getIndexShow(ks)].present_weather)}}
                    </span>
                  </CCol>
                </CRow>
                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">Sky Condition {{valrw(runways[getIndexShow(ks)])}} - 100s of feet</small>
                  </CCol>
                  <CCol xl="12" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-09':true, 'font-oranges':(!valclr(awosdata.runways[getIndexShow(ks)].sky_source)), 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].sky_source)}">
                      {{valstr(awosdata.runways[getIndexShow(ks)].sky)}}
                    </span>
                  </CCol>
                </CRow>
                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">Temperature {{valrw(runways[getIndexShow(ks)])}} - celcius</small>
                  </CCol>

                  <CCol xl="2" class="padding-0"></CCol>
                  <CCol xl="3" class="padding-0"><small>Air Temp.</small></CCol>
                  <CCol xl="3" class="padding-0"><small>Dew Point</small></CCol>
                  <CCol xl="2" class="padding-0"><small>RH</small></CCol>
                  <CCol xl="2" class="padding-0"></CCol>

                  <CCol xl="2" class="padding-0"></CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09">
                      <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].temperature_source)}">
                        {{valnum(awosdata.runways[getIndexShow(ks)].temperature)}}
                      </span>{{valstr(awosdata.runways[getIndexShow(ks)].temperature_unit)}}
                    </span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-09">
                      <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].dew_point_source)}">
                        {{valnum(awosdata.runways[getIndexShow(ks)].dew_point)}}
                      </span>{{valstr(awosdata.runways[getIndexShow(ks)].dew_point_unit)}}
                    </span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <span class="font-weight-bold font-09">
                      <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].relative_humidity_source)}">
                        {{valnum(awosdata.runways[getIndexShow(ks)].relative_humidity)}}
                      </span> {{valstr(awosdata.runways[getIndexShow(ks)].relative_humidity_unit)}}
                    </span>
                  </CCol>
                  <CCol xl="2" class="padding-0"></CCol>
                </CRow>

                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">Air Pressure {{valrw(runways[getIndexShow(ks)])}} - {{valstr(awosdata.runways[getIndexShow(ks)].qnh_unit)}}</small>
                  </CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="3" class="padding-0"><small>QNH</small></CCol>
                  <CCol xl="3" class="padding-0"><small>QFE</small></CCol>
                  <CCol xl="4" class="padding-0"><small>Density Altitude:</small></CCol>
                  <CCol xl="1" class="padding-0"></CCol>

                  <CCol xl="1" class="padding-0"></CCol>
                  <CCol xl="3" class="padding-0">
                    <span :class="{'font-weight-bold':true, 'font-11':true, 'font-manual':valclr(awosdata.runways[getIndexShow(ks)].qnh_source)}">
                      {{valnum(awosdata.runways[getIndexShow(ks)].qnh)}}
                    </span>
                  </CCol>
                  <CCol xl="3" class="padding-0">
                    <span class="font-weight-bold font-11">{{valnum(awosdata.runways[getIndexShow(ks)].qfe)}}</span>
                  </CCol>
                  <CCol xl="4" class="padding-0">
                    <span class="font-weight-bold font-11">
                      <span :class="{'font-manual':valclr(awosdata.runways[getIndexShow(ks)].density_altitude_source)}">
                        {{valnum(awosdata.runways[getIndexShow(ks)].density_altitude)}}
                      </span> {{valstr(awosdata.runways[getIndexShow(ks)].density_altitude_unit)}}
                    </span>
                  </CCol>
                  <CCol xl="1" class="padding-0"></CCol>
                </CRow>

                <CRow class="mt-3 col-xl-12 margin-0 padding-0">
                  <CCol xl="12" class="header-title padding-0">
                    <small class="font-weight-bold">Precipitation {{valrw(runways[getIndexShow(ks)])}} - mm</small>
                  </CCol>

                  <CCol xl="2" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0"><small>1 Hour</small></CCol>
                  <CCol xl="2" class="padding-0"><small>3 Hour</small></CCol>
                  <CCol xl="2" class="padding-0"><small>6 Hour</small></CCol>
                  <CCol xl="2" class="padding-0"><small>24 Hour</small></CCol>
                  <CCol xl="2" class="padding-0"></CCol>

                  <CCol xl="2" class="padding-0"></CCol>
                  <CCol xl="2" class="padding-0">
                    <!-- <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].precipitation[1])}}</span> -->
                    <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].prep1)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <!-- <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].precipitation[3])}}</span> -->
                    <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].prep3)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <!-- <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].precipitation[6])}}</span> -->
                    <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].prep6)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0">
                    <!-- <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].precipitation[24])}}</span> -->
                    <span class="font-weight-bold font-09">{{valnum(awosdata.runways[getIndexShow(ks)].prep24)}}</span>
                  </CCol>
                  <CCol xl="2" class="padding-0"></CCol>
                </CRow>
              </CCol>
            </CCol>

          </CRow>
          <CRow v-if="runways.length>0" class="mt-4 mb-3 margin-0">
            <CCol xl="1" class="padding-0 pl-2">
                <small class="font-weight-bold">METAR :</small>
            </CCol>
            <CCol xl="11" class="padding-0">
                <span class="font-weight-bold">{{valstr(awosdata.metar)}}</span>
            </CCol>
          </CRow>
          <CRow v-if="runways.length==0" class="mt-3 mb-3">
            <CCol xl="12" class="padding-0 text-center"><span>Not Found</span></CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CRow>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import CompassSVG from '../components/CompassSVG'
import WindDirection from '../components/WindDirection'
import MetarAwosRadar from '../components/MetarAwosRadar'

export default {
  name: 'Awos',
  components: {
    CompassSVG,
    WindDirection,
    MetarAwosRadar
  },
  data () {
    return {
      isLoading: false, isHLoading: false, isRefresh: false, isFilter: true,
      isFirstLoad: true,
      isChangeAirport: true,
      isChangeShowDash: true,
      timestamp: '',
      airports: [],
      awosdata: null,
      runways: [],
      arrow_rotate: [0, 0],
      knot_val: [0, 0],
      flight_rotate: [0, 0],
      showdash: [],
      vshowdash: [],
      value_show: [],
      filter_show: [],
      list_alert: [],
      check_listalert: [],
      airport_id: '',
      form: {
        airport_id: ''
      },
      errors: {
        airport_id: ''
      }
    }
  },
  watch: {
    airport_id: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { 
            if(oldVal) { this.isFirstLoad = false }
            if(!this.isFirstLoad) { 
              this.showdash = []; this.value_show = []; this.filter_show = []; this.vshowdash = [];
              this.runways = []; this.awosdata = null; this.isRefresh = true;
            }
            this.isChangeAirport = true
          }
        }
    },
    vshowdash: {
        handler: function (val, oldVal) {
          if(val!=oldVal) { 
            this.isChangeAirport = true
            this.changeShowDash(val) 
          }
        }
    },
  },
  created() {
    this.onLoad()
    this.showUserconfig()
    // this.getAirport()
  },
  mounted() {
    this.$store.commit('set', ['sidebarMinimize', true])
  },
  beforeDestroy() {
    clearInterval(this.intervalTime)
    clearInterval(this.intervalAWOS)
    this.$store.commit('set', ['sidebarMinimize', false])
  },
  methods: {
    ...mapActions('airport', ['indexAPT']),
    ...mapActions('profile', ['showPFL']),
    ...mapActions('dashboard', ['storeAWOS']),
    ...mapActions('configs', ['indexUCFG']),
    onLoad() {
      this.intervalTime = setInterval(this.getNow, 1000)
      this.intervalAWOS = setInterval(this.postAWOS, 10000)
    },
    async getAirport(){
        this.isHLoading = true
        await this.showPFL().then((response) => {
            response.airport_rights.forEach((item, index) => {
                this.airports[index] = { value: item.id, label: item.name+" ("+item.code+")" }
            })
            this.isHLoading = false
            if(response.airport_rights.length>0) {
              this.airport_id = this.airports[0].value
              this.form.airport_id = this.airports[0].value
              this.postAWOS()
            }
        }).catch((error) => {
            this.flashMessage.error({ title: 'Error', message: error })
            this.isHLoading = false
        })
    },
    async postAWOS(){
      if(this.form.airport_id) {
        this.isLoading = true
        await this.storeAWOS(this.form).then((response) => {
          this.awosdata = response
          let objRunways = Object.keys(response.runways).map((key) => {
            return response.runways[key]
          })
          this.runways = Object.keys(response.runways).map((key) => {
            return parseInt(key)
          })

          if(this.form.airport_id != 4){
            this.runways.sort((a, b) => (a < b) ? 1 : -1)
            objRunways.sort((a, b) => (a < b) ? 1 : -1)
          }

          var dinamicsd = []
          this.runways.forEach((item, i) => {
            var items = item.toString().length < 2 ? '0'+String(item):String(item)
            var ir = i+(i+1)-1
            var il = i+(i+1)
            dinamicsd[ir] = { value: 'ra:'+i+':'+items, label: 'Magnetic Winds rwy '+items }
            dinamicsd[il] = { value: 'lg:'+i+':'+items, label: 'Lightning rwy '+items }
          })
          if(!this.isFirstLoad) {
            // this.runways.forEach((item, i) => {
            //   var items = item.length<2 ? '0'+item:item
            //   var ir = i+(i+1)-1
            //   var il = i+(i+1)
            //   this.showdash[ir] = { value: 'ra:'+i+':'+items, label: 'Magnetic Winds '+items }
            //   this.showdash[il] = { value: 'lg:'+i+':'+items, label: 'Lightning '+items }
            // })
            if(this.isChangeAirport == true){
              this.showdash = dinamicsd
            }
            this.vshowdash = this.showdash
          }
          this.awosdata.runways = objRunways
          if(this.runways.length>0){this.getRotate(objRunways)}

          /* Start Alert */
          this.list_alert = []
          var store_listalert = this.$store.state.listalert ? this.$store.state.listalert : []
          response.alerts.forEach((item, i) => {
            this.list_alert.push(item.message)
          })
          this.check_listalert = this.list_alert.filter(function(item) {
            if(!store_listalert.includes(item)) return true
          })
          if(this.check_listalert.length>0) {
            this.check_listalert.forEach((item, i) => {
              this.flashMessage.warning({ title: 'Information', message: item, time: 7000 })
            })
            localStorage.setItem('listalert', JSON.stringify(this.list_alert))
            this.$store.commit('SET_LISTALERT', this.list_alert, { root: true })
          }
          /* End Alert */
          
          this.isRefresh = false
          this.isLoading = false
        }).catch((error) => {
          this.flashMessage.error({ title: 'Error', message: error.statusText })
          this.getError(error.data)
          this.isLoading = false
        })
      }
    },
    showUserconfig() {
      this.isHLoading = true
      this.indexUCFG().then((response) => {
        response.available_airports.forEach((item, index) => {
          this.airports[index] = { value: item.id, label: item.name+" ("+item.code+")" }
          // this.listrunways[item.id] = item.runway_modified
        })
        /* Awos */
        this.airport_id = response.awos_selected ? response.awos_selected.id:''
        this.form.airport_id = response.awos_selected ? response.awos_selected.id:''
        if(this.form.airport_id<1) {
          this.airport_id = this.airports[0].value
          this.form.airport_id = this.airports[0].value
        }
        response.available_runways.forEach((item, index) => {
          var rwdata = item.split(":")
          var rwname = (rwdata[0]=='ra') ? 'Magnetic Winds RWY ' : 'Lightning RWY '
          var rw = (rwdata[2])?rwdata[2]:''
          this.showdash[index] = { value: item, label: rwname+rw }
        })
        this.filter_show = response.runway_selected
        //[response.runway_selected[2], response.runway_selected[3], response.runway_selected[0], response.runway_selected[1]]
        // this.filter_show.sort((a, b) => (a < b) ? 1 : -1)
        this.value_show = this.showdash.filter(function(item) {
          if(response.runway_selected.includes(item.value)) return true
        })
        this.isHLoading = false
        this.postAWOS()
      }).catch((error) => {
        this.flashMessage.error({ title: 'Error', message: error })
        this.isHLoading = false
      })
    },
    getRotate(val) {
      var arrow1 = val[0]?val[0].wind_direction:0
      var arrow2 = val[1]?val[1].wind_direction:0
      this.arrow_rotate = [arrow1, arrow2]

      var knot1 = val[0]?val[0].wind_speed:0
      var knot2 = val[1]?val[1].wind_speed:0
      this.knot_val = [knot1, knot2]

      var flight_val = 0;
      if(this.runways.length>0) {
        var rw1 = this.runways[0]?parseInt(this.runways[0]):0
        var rw2 = this.runways[1]?parseInt(this.runways[1]):0
        flight_val = rw1>rw2 ? rw1:rw2
      }
      var flight1 = flight_val*10
      var flight2 = flight_val*10
      this.flight_rotate = [flight1, flight2]
    },
    onChangeAirbase(v) {
      this.airport_id = v
      this.form.airport_id = v
      this.postAWOS()
    },
    getNow() {
      var gmt = moment().format('ZZ')
      var now = moment().locale('id').format('DD MMMM YYYY HH:mm')
      if(gmt=='+0700') {
        this.timestamp = now+' WIB'
      } else if(gmt=='+0800') {
        this.timestamp = now+' WITA'
      } else if(gmt=='+0900') {
        this.timestamp = now+' WIT'
      } else {
        this.timestamp = now
      }
    },
    getViewRunway(val) {
      var type = val.split(":");
      if(type[0]=='ra') { return true } else { return false }
    },
    getViewLightning(val) {
      var type = val.split(":");
      if(type[0]=='lg') { return true } else { return false }
    },
    getIndexShow(val) {
      var index = val.split(":");
      return index[1]
    },
    changeShow(val) {
      this.value_show = val
      this.filter_show = val.map(function (obj) {
        return obj.value
      })
      this.isChangeAirport = false
    },
    changeShowDash(val) {
      this.value_show = val
      this.filter_show = val.map(function (obj) {
        return obj.value
      })
    },
    showfilter() {
      this.isFilter = !this.isFilter;
    },
    valnum(v){
      if(!v) return 0
      else return v
    },
    valstr(v){
      if(!v) return '-'
      else return v
    },
    valarr(v){
      if(!v) return []
      else return v
    },
    valclr(v){
      if(!v) return false
      else if(v=='manual') return true
      else return false
    },
    valrw(v){
      if(v.toString().length < 2 ) return '0'+String(v)
      else return v
    },
    getError(data){
      if(typeof(data) == 'object' && data){ if(data.errors) { 
        this.errors.airport_id = data.errors.airport_id ? data.errors.airport_id[0] : ''
      } else { this.errors = { airport_id: '' }} }
    }
  }
}
</script>
